/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import Accordion from './Accordion';
import SiteFooter from './SiteFooter';
import UpcomingEvents from './UpcomingEvents';
import UpcomingEventsEn from "./UpcomingEventsEn";
import UpcomingEventsEn2 from "./UpcomingEventsEn2";
import UpcomingEventsEs from "./UpcomingEventsEs";
import UpcomingEventsEs2 from "./UpcomingEventsEs2";

import './Layout.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDollarSign,
  faEnvelope,
  faCheck,
  faTimes,
  faLock,
  faUnlock,
  faLanguage,
  faAngleDown,
  faCalendarDay,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faDollarSign,
  faEnvelope,
  faCheck,
  faTimes,
  faLock,
  faUnlock,
  faLanguage,
  faAngleDown,
  faCalendarDay,
  faDownload,
);

const shortcodes = {
  Accordion,
  UpcomingEvents,
  UpcomingEventsEn,
  UpcomingEventsEn2,
  UpcomingEventsEs,
  UpcomingEventsEs2,
};

const Layout = ({ children, meta, styles }) => {
  return (
    <MDXProvider components={shortcodes}>
      <main>
        <React.Fragment>{children}</React.Fragment>
      </main>
      <SiteFooter />
    </MDXProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
